import apiClient from './ApiClient';

export const socialRefresh = async (socialToken) => {
    const res = await apiClient.post('/oauth2/refresh', { token: socialToken });
    return res.data;
};

export const modifyMember = async (member) => {
    const res = await apiClient.put(`/member/modify`, member, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
    return res.data;
};

export const uploadIcon = async (formData) => {
    const res = await apiClient.post(`/member/uploadIcon`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
    return res.data;
};

export const logoutPost = async () => {
    const response = await apiClient.post('/member/logout');
    return response.data;
};

export const searchIndustries = async (params) => {
    const queryParams = new URLSearchParams();
    if (params.classificationYear) {
        queryParams.append('classificationYear', params.classificationYear);
    }
    if (params.classificationCode) {
        queryParams.append('classificationCode', params.classificationCode);
    }
    if (params.applicationScopeCriteria) {
        queryParams.append('applicationScopeCriteria', params.applicationScopeCriteria);
    }

    const queryString = queryParams.toString();
    const res = await apiClient.get(`/member/industry/search?${queryString}`);
    return res.data;
};

export const checkNicknameDuplicate = async (nickname) => {
    const res = await apiClient.get(`/member/checkNickname`, { params: { nickname } });
    return res.data.isDuplicate;
};